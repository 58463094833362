.custom-card { 
    width: 6rem; 
    height: 6rem;
}

.custom-card:hover {
    background-color: lightgreen;
}

.btn {
    background-color: #02ad02;
    margin: 2rem;
    border-color: black;
}

.btn:hover {
    background-color: #017801;
    border-color: black;
}

.btn:active {
    background-color: black !important;
    border-color: black !important;
}